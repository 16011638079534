exports.components = {
  "component---src-docs-02-features-00-home-md": () => import("./../../../src/docs/02_features/00_home.md" /* webpackChunkName: "component---src-docs-02-features-00-home-md" */),
  "component---src-docs-02-features-01-basic-md": () => import("./../../../src/docs/02_features/01_basic.md" /* webpackChunkName: "component---src-docs-02-features-01-basic-md" */),
  "component---src-docs-02-features-02-additional-md": () => import("./../../../src/docs/02_features/02_additional.md" /* webpackChunkName: "component---src-docs-02-features-02-additional-md" */),
  "component---src-docs-03-get-started-md": () => import("./../../../src/docs/03_get-started.md" /* webpackChunkName: "component---src-docs-03-get-started-md" */),
  "component---src-docs-04-config-md": () => import("./../../../src/docs/04_config.md" /* webpackChunkName: "component---src-docs-04-config-md" */),
  "component---src-docs-05-sla-md": () => import("./../../../src/docs/05_sla.md" /* webpackChunkName: "component---src-docs-05-sla-md" */),
  "component---src-docs-06-faq-md": () => import("./../../../src/docs/06_faq.md" /* webpackChunkName: "component---src-docs-06-faq-md" */),
  "component---src-docs-07-dashboard-md": () => import("./../../../src/docs/07_dashboard.md" /* webpackChunkName: "component---src-docs-07-dashboard-md" */),
  "component---src-docs-index-md": () => import("./../../../src/docs/index.md" /* webpackChunkName: "component---src-docs-index-md" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/newsList.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

